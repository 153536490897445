import {ViewBasic} from '@quatrecentquatre/manage-me';
import {bindAll} from 'underscore'
import lightGallery from "lightgallery";
import lgVideo from "lightgallery/plugins/video/lg-video.umd";
import "lightgallery/scss/lightgallery.scss";

export class PagebuilderGallery extends ViewBasic {
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['nextSlide', 'previousSlide', 'showPlayButton', 'hidePlayButton', 'mouseMove', 'checkAndTogglePlayButton']);

        let scope = this;

        this.index = 1;
        this.slideCount = this.el.querySelectorAll('.images-ctn.large img').length;
        this.addEvents();

        lightGallery(this.el.querySelector('.lightgallery'), {
            licenseKey: process.env.LIGHTGALLERY_LICENSE,
            plugins: [lgVideo],
            nextHtml: scope.el.querySelector('.arrow-controls.next').outerHTML.toString().replace('button', 'span'),
            prevHtml: scope.el.querySelector('.arrow-controls.previous').outerHTML.toString().replace('button', 'span'),
            speed: 500,
            hideScrollbar: true, //Cette option empêche le scroll de la page quand la galerie est ouverte
        });
    }

    addEvents() {
        let scope = this;

        this.el.querySelector('.next').addEventListener('click', this.nextSlide);
        this.el.querySelector('.previous').addEventListener('click', this.previousSlide);

        window.addEventListener('resize', this.checkAndTogglePlayButton);

        // if (window.innerWidth > window.SETTINGS.BREAKPOINTS.SM_MAX) {
        //     this.el.addEventListener('mousemove', this.mouseMove);
        //
        //     this.el.querySelectorAll('.video').forEach(function (element) {
        //         element.addEventListener('mouseenter', scope.showPlayButton);
        //         element.addEventListener('mouseleave', scope.hidePlayButton);
        //     })
        // } else {
        this.checkAndTogglePlayButton();
        // }
    }

    removeEvents() {
        let scope = this;

        this.el.querySelector('.next').removeEventListener('click', this.nextSlide);
        this.el.querySelector('.previous').removeEventListener('click', this.previousSlide);
        window.removeEventListener('resize', this.checkAndTogglePlayButton);

        // if (window.innerWidth > window.SETTINGS.BREAKPOINTS.SM_MAX) {
        //     this.el.addEventListener('mousemove', this.mouseMove);
        //
        //     this.el.querySelectorAll('.video').forEach(function (element) {
        //         element.removeEventListener('mouseenter', scope.showPlayButton);
        //         element.removeEventListener('mouseleave', scope.hidePlayButton);
        //     })
        // }
    }

    mouseMove(e) {
        var x = e.pageX - e.currentTarget.offsetLeft;
        var y = e.pageY - e.currentTarget.offsetTop;

        const iconPlay = this.el.querySelector('.icon-play');
        iconPlay.style.top = (y - (iconPlay.offsetHeight / 2)) + 'px';
        iconPlay.style.left = (x - (iconPlay.offsetWidth / 2) - this.el.querySelector('.centered-content').offsetLeft) + 'px';
    }

    showPlayButton() {
        this.el.querySelector('.icon-play').classList.add('active');
    }

    hidePlayButton() {
        this.el.querySelector('.icon-play').classList.remove('active');
    }

    /**
     * Increase index on next button
     * Listens to button in order to toggle play icon for videos on mobile layout.
     * @param e
     */
    nextSlide(e) {
        this.index++;
        this.updateSlideshowStatus();

        // if (window.innerWidth <= window.SETTINGS.BREAKPOINTS.SM_MAX) {
        this.checkAndTogglePlayButton();
        // }
    }

    /**
     * Decrease index on previous button
     * Listens to button in order to toggle play icon for videos on mobile layout.
     * @param e
     */
    previousSlide() {
        this.index--;
        this.updateSlideshowStatus();

        // if (window.innerWidth <= window.SETTINGS.BREAKPOINTS.SM_MAX) {
        this.checkAndTogglePlayButton();
        // }
    }


    /**
     * Check if the active slide is a video and toggle the play button
     * This function is only used for mobile layout.
     */
    checkAndTogglePlayButton() {
        // const scope = this;
        // let isVideoActive = false;
        //
        // this.el.querySelectorAll('.video').forEach(function (element) {
        //     if (element.classList.contains('active')) {
        //         scope.showPlayButton();
        //         isVideoActive = true;
        //     }
        // });
        //
        // // If no video is active, hide the play button
        // if (!isVideoActive) {
        //     scope.hidePlayButton();
        // }
        // if (window.innerWidth > window.SETTINGS.BREAKPOINTS.SM_MAX) {
        const iconPlay = this.el.querySelector('.icon-play');
        if (this.el.querySelector('.video').classList.contains('active')) {
            iconPlay.classList.add('active');
        } else {
            iconPlay.classList.remove('active');
        }
    }


    /**
     * Update Slideshow status (progress / total slide) and animate the new active slides in.
     * @param e
     */
    updateSlideshowStatus() {
        this.el.querySelector('.next').disabled = false;
        this.el.querySelector('.previous').disabled = false;

        if (this.index === this.slideCount) {
            this.el.querySelector('.next').disabled = true;
        } else if (this.index === 1) {
            this.el.querySelector('.previous').disabled = true;
        }

        //reset active state
        this.el.querySelectorAll('.images-ctn img.active').forEach(function (element) {
            element.classList.remove('active');
        });
        //add active state to new images

        this.el.querySelectorAll('.images-ctn.large img')[this.index - 1].classList.add('active');
        //sometimes the small image will not be available (end of slideshow)
        if (this.el.querySelectorAll('.images-ctn.small img')[this.index]) {
            this.el.querySelectorAll('.images-ctn.small img')[this.index].classList.add('active');
        }

        //update index visible to the user
        this.el.querySelector('.index').innerHTML = this.index;
    }
}

Me.views['PagebuilderGallery'] = PagebuilderGallery;
