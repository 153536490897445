import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import CustomEase from 'gsap/CustomEase';
import Rellax from 'rellax/rellax.min';
import { bindAll } from 'underscore'
import AOS from 'aos/dist/aos';

class Main {
    constructor() {
        this.initialize();
        this.lastEventDetail = null;

    }

    initialize() {
        bindAll(this, ['resize','barbaPageLoaded','blur','focus', 'dataLayerPush']);

        this.screenSize = 0;

        //this class is applying css transition duration of 0 to prevent animating from resetting when refreshing the page.
        document.querySelector('body').classList.remove('preload');

        this.breakpoints = [
            window.SETTINGS.BREAKPOINTS.SM_MIN,
            window.SETTINGS.BREAKPOINTS.MD_MIN,
            window.SETTINGS.BREAKPOINTS.LG_MIN
        ];

        this.addEvents();

        this.barbaPageLoaded();

        ScrollTrigger.config({ignoreMobileResize: true})
    }

    barbaPageLoaded(){
        setTimeout(function(){
            window.dispatchEvent(new Event('resize'));
        },100);

        this.initParallax();
        AOS.init();
    }

    addEvents() {
        this.removeEvents();
        window.addEventListener('resize', this.resize);
        window.addEventListener('pageTransition', this.barbaPageLoaded);
        window.addEventListener('blur', this.blur);
        window.addEventListener('focus', this.focus);
        window.addEventListener('dataLayer.push', this.dataLayerPush);
    }

    removeEvents() {
        window.removeEventListener('resize', this.resize);
        window.removeEventListener('pageTransition', this.barbaPageLoaded);
        window.removeEventListener('blur', this.blur);
        window.removeEventListener('focus', this.focus);
        window.removeEventListener('dataLayer.push', this.dataLayerPush);

        if(this.rellax){
            this.rellax.destroy();
        }
    }

    /**
     * Init parallax on element with parallax class
     */
    initParallax(){
        if(this.rellax){
            this.rellax.destroy();
        }

        if(document.querySelectorAll('.rellax').length != 0){
            this.rellax = new Rellax('.rellax');
        }
    }

    blur(){
        window.dispatchEvent(new Event("window.blur"));
    }

    focus(){
        window.dispatchEvent(new Event("window.focus"));
    }

    resize(e) {

        //prevent mobile resize triggered by addressbar collapsing to retrigger our animation.
        if(this.screenSize == document.body.clientWidth){
            return;
        }else{
            this.screenSize = document.body.clientWidth;
        }

        let breakpoint;
        let viewPortSize = this.GET_OUTER_WIDTH(document.querySelector('html'));

        if(viewPortSize <= window.SETTINGS.BREAKPOINTS.XS_MAX){
            window.isMobile = true;
        } if(viewPortSize <= window.SETTINGS.BREAKPOINTS.SM_MAX) {
            window.isTablet = true;
        }
        else{
            window.isTablet = false;
            window.isMobile = false;
        }

        if (viewPortSize < this.breakpoints[0]) {
            breakpoint = String(this.breakpoints[0] - 1) + "-";
        }

        for (let i in this.breakpoints) {
            if (this.breakpoints[Number(i) + 1] != undefined && viewPortSize >= this.breakpoints[i] && viewPortSize < this.breakpoints[Number(i) + 1]) {
                breakpoint = String(this.breakpoints[i]) + "-" + String(this.breakpoints[Number(i) + 1] - 1);
            }
            if (viewPortSize >= this.breakpoints[this.breakpoints.length - 1]) {
                breakpoint = String(this.breakpoints[i]) + "+";
            }
        }

        if (window.SETTINGS.BREAKPOINT != breakpoint) {
            window.SETTINGS.BREAKPOINT = breakpoint;

            let eventDispatch = new CustomEvent("breakpointChanged", {bubbles: true, detail: {
                    breakpoint: breakpoint
                }
            });

            this.initParallax();

            //dispatch breakpointChanged
            window.dispatchEvent(eventDispatch);
        }

        window.dispatchEvent(new Event("window.resize"));

    }

    GET_OUTER_WIDTH(element) {
        let width = element.offsetWidth;
        let style = getComputedStyle(element);

        width += parseInt(style.marginLeft) + parseInt(style.marginRight);
        return width;
    }

    dataLayerPush(event) {
        window.dataLayer = window.dataLayer || [];

        // Check if the current event detail is the same as the last one
        const currentDetail = JSON.stringify(event.detail);

        if (this.lastEventDetail !== currentDetail) {
            // If different, push the event to the dataLayer and update last event detail
            window.dataLayer.push(event.detail);
            this.lastEventDetail = currentDetail;
        }
    }

}

gsap.registerPlugin(ScrollTrigger, SplitText, CustomEase);

document.addEventListener('DOMContentLoaded', function() {
    new Main();
});
